/* ChatModal.css */
.chat-modal {
  position: absolute;
  overflow: auto;
  bottom: 0 !important;
  right: 0 !important;
  margin: 0;
  width: 26rem !important;
  // height: 29rem !important;
  max-width: 100%;
  max-height: 30rem;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  &-toggle-icon {
    position: absolute;
    right: 3%;
    top: 10%;
  }

  &.ui.modal>.content {
    overflow: auto;
    max-height: 310px;
    min-height: 310px;
  }

  &.ui.modal>.header-style {
    font-size: .8rem !important;
  }

  &.ui.modal>.header-style>.header-style-font {
    font-size: 1.0rem !important;
  }

  .header-lastseen {
    font-size: 10px;
    padding-left: 22px;
  }

  .modal-smaile {
    position: absolute;
    bottom: 65px;
    height: 200px;
    overflow: auto;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
  }
}

.ui.modal .actions {
  padding: 0.8rem !important;
}

.chat-bubble {
  display: flex;

  &.content-style {
    font-size: .9rem !important;
  }

  position: relative;

  &:hover .delete-icon {
    display: block;
  }
}

.chat-bubble__right {
  position: relative;
  display: inline-block;
  width: 100%;

  .delete-icon {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
}

.chat-bubble__image {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.chat-item {
  margin: 10px 0;
}

.left-message .chat-bubble {
  background-color: #e0f7fa;
  border-radius: 15px;
  padding: 10px;
  margin-right: 50px;
}

.right-message .chat-bubble {
  background-color: #fff4e4;
  border-radius: 15px;
  padding: 10px;
  margin-left: 50px;
}

.left-message .chat-bubble-date {
  margin-right: 50px;
  font-size: 10px;
}

.right-message .chat-bubble-date {
  margin-left: 50px;
  font-size: 10px;
}

.user-name {
  margin: 0;
  font-weight: bold;
  font-size: 13px;
}

.user-message {
  margin: 0;
  font-size: 13px;
}
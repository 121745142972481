@import "../../styles/core/core-variables";

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


.layout-container {
  min-height: 500px;
  position: relative;

  &-sidebar {
    width: 15rem;
    background: linear-gradient(166.79deg, #FFBEFD 6.95%, #AE73DC 45.44%, #1C99E1 82.4%);
    animation: slideInFromLeft 1s ease-in-out forwards;
  }

  &-content {
    width: calc(100% - 15rem);
    animation: fadeIn 1s ease-in-out forwards;
  }
}



.nav-link {
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;


  &:hover {
    color: $purple-shade-1;
  }
}

.nav-link.active {
  background-color: $secondary-color;
  color: $purple-shade-1;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.child-link {
  font-size: 1rem;
  font-weight: 400;
  padding: 0.5rem 0.5rem 0.5rem 0.8rem;

  &:hover {
    color: $purple-shade-1;
  }
}

.child-link.active {
  color: $purple-shade-1;
}
.fav-overlay-container {
  width: 25rem;
  z-index: 8;
  right: 0;
  top: 0;
  background: white;
  transition: transform 0.3s ease, opacity 0.3s ease; 

  &-content {
    height: calc(100% - 6rem);
    opacity: 0.9;
    overflow-y: auto;
    transition: opacity 0.3s ease; 
  }
}

.fav-overlay-background {
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100% - 25rem);
  height: 100%;
  background: #3c3e44e5;
  z-index: 100;
  transition: opacity 0.3s ease;
}



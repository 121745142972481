@import "../../styles/core/core-variables";

.user-rows {
  border-bottom: 1px solid $grey-shade-6;
  margin-bottom: 0.25rem;
}

.user-image {
  width: 60px;
  height: 60px;
  border: 1px solid $grey-shade-3;
  border-radius: 10px;

  img {
    object-fit: contain;
    border-radius: inherit;
  }
}

.userTable-height {
  height: calc(100% - 7.25rem);

  table {
    tbody {
      td {
        padding: 0.6rem 0.25rem;
      }
    }

  }
}

.search-input {
  input {
    margin: 0 !important;
    height: 2.5rem !important;
  }
}

.users-phoneInput {
  top: 0.125rem !important;
}

@media screen and (min-width: 1919px) {
  .users-phoneInput {
    top: 0.6rem !important;
  }

}


// .userInput-label {
//     font-size: 1rem;
//     line-height: 1.25rem;
//     font-weight: 600;
//     padding-left: 0.25rem;
//   }
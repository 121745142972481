@import "../../../styles/core/core-variables";

.add-influencer-modal {
  &.ui.fullscreen.modal {
    width: 100% !important;
    margin: 0 !important;
    height: 100%;
    top: 0;
  }

  &.ui.modal>.content {
    width: 100%;
    height: 100%;
  }

  &-toggle-icon {
    position: absolute;
    right: 3%;
    top: 3%;
  }
}



.profile-detail {
  &-img {
    width: 2.75rem;
    height: 2.75rem;
    margin-right: 0.5rem;

    img {
      border-radius: 50%;
      width: 100%;
    }
  }

  &-text1 {
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 0.875rem;
  }

  &-text2 {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 0.65rem;
  }
}

.category-status {
  border-radius: 20px;
  border: 1px solid $border-bottom-color;
  background: $grey-shade-7 ;
  padding: 0.25rem 0.25rem;
}

.active-alphabet {
  background-color: $primary-color;
  color: $secondary-color;
}

.location-Input {
  border-radius: .28571429rem !important;
  height: 2.71428571em !important;
  margin: 0 !important;
}

.fav-button {
  position: fixed;
  top: 12%;
  right: 3%;
}
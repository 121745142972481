.posts-wrapper {
  height: 95%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.post-like-text {
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.25rem;
}

.post-container {
  width: 25%;
  cursor: pointer;

  &-text {
    flex-direction: column;
  }

  &-img {
    height: 200px;
    min-height: 200px;

    img {
      object-fit: contain;
    }
  }
}

.post-container-box {
  box-sizing: border-box;
  box-shadow: 0px 2px 3px 2px #00000040;
}

.content-view {
  max-height: 30rem;
  // height: 50rem;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.info-title {
  font-size: 0.7rem;
  font-weight: 500;
}

.info-value {
  font-size: 0.85rem;
  font-weight: 700;
}

.info-subTitle{
  font-size: 0.6rem;
  // line-height: 0.75rem;
  // margin-top: 0.1rem;
}

.engagement-chart1 {
  max-width: 25rem;
  height: 15rem;
}

@media screen and (min-width: 1919px) {
  // .post-like-text {
  //     font-size: 0.75rem;
  // }

  .info-title {
    font-size: 0.8rem;
  }

  .info-value {
    font-size: 1rem;
  }

  // .post-container {
    // width: 20%;
  // }

  .post-container-text {
    width: 100;
    // flex-wrap: wrap;
    overflow: hidden;
    padding: 0.25rem 1.5rem;
    flex-direction: row;
    justify-content: space-between;
  }

  .posts-wrapper {
    height: 100%;
  }
}
.cursor {
  &-pointer {
    cursor: pointer;
  }

  &-move {
    cursor: move;
  }

  &-default {
    cursor: default;
  }

  &-notAllowed {
    cursor: not-allowed;
  }
}

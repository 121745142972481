.header-layout {
  height: 6rem;

  &-top {
    font-size: 1.75rem;
    font-weight: 400;
    line-height: 1.75rem;
    height: 65%;

    &-profile {
      width: 2.5rem;
      height: 2.5rem;

      img {
        width: 100%;
        border-radius: 50%;
      }
    }
  }

  &-bottom {
    height: 35%;
  }

  &-content {
    height: calc(100% - 6rem);
  }

  &-pagination {
    height: calc(100% - 10rem);
    padding-bottom: 0.25rem;

    &-height {
      height: 3rem;
    }
  }
}

.header-layout-content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #999999;
  border-radius: 10px;
  background-color: #f5f5f5;
}

.header-layout-content::-webkit-scrollbar {
  display: none;
  width: 5px;
  background-color: #f5f5f5;
}

.header-layout-content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #999999;
  background-color: #999999;
}

.breadcrumb-item {
  color: white;
  text-decoration: none;
  margin-right: 0.35rem;
}

.breadcrumb-item.active {
  font-weight: bold;
  color: #F6B6FA;
}

.breadcrumb-divider {
  margin-right: 0.35rem;
}

.notification-icon {
  top: -1.12rem;
  // right: 0rem;
  left: 1.25rem;
}

.notification-count {
  border-radius: 4px;
  padding: 0.125rem 0.5rem;
}

.chatNotification-count {
  background-color: #FF0000;
  border-radius: 4px;
  padding: 4px;
  // height: 1rem;
  font-size: 0.5rem;
  line-height: 0.5rem;
  text-align: center;
  color: white;
  top: -0.5rem;
  left: 85%;
  // right: 0;
}
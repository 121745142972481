.auth-banner-section {
  border-radius: 20px;

  &-img-right {
    bottom: 0;
    right: -4.5rem;
  }

  &-img-center {
    bottom: 0;
    left: 15%;
  }

  &-text-top {
    font-size: 2.12rem;
    line-height: 3.12rem;
    font-weight: 700;
  }

  &-text-bottom {
    font-size: 3.12rem;
    line-height: 3.12rem;
    font-weight: 700;
    text-align: left;
    bottom: 0;
    // left: 2px;
    z-index: 1;
    position: absolute;
  }

  &-back {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

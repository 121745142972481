@import "../../styles/core/core-variables";

.ui.modals.dimmer {
  background-color: rgba(17, 17, 17, 0.41);
}

.ui.modal {
  background-color: $secondary-color;

  .header {
    border-bottom: 1px solid $grey-shade-1;
    padding: $padding-2;
    position: relative;
    &:not(.ui) {
      font-weight: normal;
    }

    &-close-button {
      position: absolute;
      top: 25%;
      right: 3%;
  }
  }
  .content {
    padding: $padding-6;
    &.nopadding {
      padding: $padding-0;
    }

    &.background-grey-shade-7 {
      background-color: $grey-shade-1;
    }
  }

  .actions {
    border-top: 1px solid #00000020;
    background-color: transparent;
    padding: $padding-6;
  }
}
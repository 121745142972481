@import "../../styles/core/core-variables";

.custom-pagination-container {
  height: 3.125rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.last-30-days-text {
  position: absolute;
  /* This allows you to position it at the bottom-right */
  bottom: 9px;
  right: 22px;
  font-size: 12px;
  /* You can adjust this to your preference */
  color: #000;
  /* Adjust the color */
}
.custom-table-container {
  background-color: transparent;
  overflow: auto;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-bottom: 0.5rem;
  &::-webkit-scrollbar {
    display: none;
  }

  table {
    width: 100%;
    // height: 100%;
    overflow: auto;
    border-collapse: collapse;

    thead {
      position: sticky;
      top: 0;
      left: 0;
      z-index: 8;
      border-radius: 30px !important;
      border-bottom: 1px solid $border-bottom-color;
      background-color: $secondary-color;
    }

    thead tr {
      color: black;
      text-align: left;
      background-color: #ffffffa6;
      z-index: 2;
    }

    tbody {
      position: relative;
      background-color: $secondary-color;
      z-index: 1;
      font-size: 1rem;
      line-height: 1.2rem;
      color: $black-shade-1;
    }

    tbody tr {
      border-bottom: 1px solid $border-bottom-color;

      &:last-child {
        border-bottom: none;
      }
    }

    // tr {
    //     transition: background-color 0.3s ease;
    //   }

    //   tr:hover {
    //     background-color: #f0f0f0; 
    //   }

    th,
    td {
      padding: 10px 12px;
      word-wrap: break-word;
    }

    // @keyframes rowHighlight {
    //     0% { background-color: transparent; }
    //     50% { background-color: #f0f0f0; }
    //     100% { background-color: transparent; }
    //   }

    //   tr {
    //     animation: rowHighlight 2s infinite; /* Apply animation to rows */
    //   }
  }
}
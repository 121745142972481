.user-content {
  background-color: #F6F6F6;
}

.user-table-height {
  height: calc(100% - 11rem);
  border-radius: 20px;
}

.users-dropdown {
  min-height: 2.25rem !important;
  height: 2.5rem;
  border-radius: 10px !important;
  line-height: 0.75rem !important;

  .divider.text {
    text-align: center;
  }
  &.ui.selection.dropdown>.dropdown.icon {
    padding: 0.7rem !important;
  }
  
}

.search-users {
  input {
    height: 2.5rem !important;
  }
}
@import "../../../styles/core/core-variables";


.invite-modal {
    &-close-button {
        position: absolute;
        top: 25%;
        right: 10%;
    }
}

.browseFile-container {
    border-radius: 5px;
    border: 2px #c3c3c3 solid;
}

.dragDrop-container {
    border-radius: 5px;
    border: 1px $blue-shade-4 dashed;
    background-color: $light-blue-shade2;
}

.dragging {
    border: 2px dashed #7177f1;
}
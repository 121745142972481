@import "../../styles/core/core-variables";

.campaign-status {
  border-radius: 30px;
  font-size: 0.875rem;
  line-height: 0.875rem;
  padding: 6px 6px;
  // max-height: 1.65rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.campaign-image {
  width: 6.25rem;
  height: 5rem;
  border: 1px solid $grey-shade-3;
  border-radius: 10px;

  img {
    object-fit: contain;
    border-radius: 10px;
    // width: 100%;
  }
}

.campaign-detail {
  &-name {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.85rem;
    color: $blue-shade-2;
  }

  &-subName {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25rem;
    color: $grey-shade-4;
  }
}

.campaign-type {
  font-size: 0.75rem;
  font-weight: 400;
  // line-height: 0.75rem;
}

.campaign-left-header,
.campaign-right-header {
  height: 4rem;
}

.campaign-left-content {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 4rem);

  &::-webkit-scrollbar {
    display: none;
  }
}

.campaign-right-content {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 4rem);

  &::-webkit-scrollbar {
    display: none;
  }
}

.icon-title {
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 0.75rem;
}

.icon-subtitle {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 0.75rem;
}

.userList {
  border-radius: 50px;
}

.array-items {
  padding: 0.25rem 0.5rem;
  border-radius: 20px;
  // background:#EEEEEE ;
  font-size: 0.65rem;
  line-height: 0.75rem;

  &-hidden {
    padding: 0.5rem;
  }
}

.influencer-summary {
  &-card {
    width: 10rem;
    height: 8.5rem;
    border-radius: 10px;
  }

  &-text1 {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.25rem;
    color: $blue-shade-5;
  }

  &-text2 {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 2.5rem;
    color: $blue-shade-5;
  }

  &-text3 {
    font-size: 0.7rem;
    font-weight: 400;
    line-height: 1.25rem;
    color: $blue-shade-5;
  }
}

.ui.compact.selection.dropdown {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.campaign-managers {
  .ui.multiple.selection.dropdown {
    height: 2.375rem !important;
  }

  .divider.text {
    line-height: 0.214286em !important;
  }
}

.product-image {
  height: 4.35rem;
  margin-top: 0.85rem;
}

.deliverable-rows {
  border-bottom: 1px solid $grey-shade-6;
  margin-bottom: 0.35rem;
}


.filter-input {
  input {
    margin: 0.25rem !important;
    height: 2.15rem !important;
    padding-left: 1rem !important;
  }
}

.divider text,
.ui.inline.dropdown>.text {
  font-size: 0.75rem !important;
  font-weight: 400;
}

.influencer-chat {
  background-color: $danger-color;
  border-radius: 4px;
  padding: 3px 4px;
  height: 1rem;
  font-size: 0.5rem;
  line-height: 0.5rem;
  text-align: center;
  color: white;
  top: -0.65rem;
  left: 70%;
}

.campaign-multiselect {
  .ui.multiple.selection.dropdown {
    display: flex;
    flex-wrap: wrap;
    height: auto !important;
    align-items: center;
    padding: 0.35rem !important;
  }
}


@media screen and (min-width: 1919px) {
  .campaign-managers {
    .ui.multiple.selection.dropdown {
      height: 3.125rem !important;
    }
  }
}
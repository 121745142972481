@import "../../styles/core/core-variables";

.profile-left-container {
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.current-info {
  border-bottom: 3px solid $blue-shade-1;
}

.profile-image {
  width: 10.625rem;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.profile-status {
  border-radius: 20px;
  border: 1px solid $border-bottom-color;
  background: $grey-shade-7 ;
  padding: 0.25rem 0.5rem;
}

.profile-gener {
  border-radius: 10px;
}

.addressTag {
  font-size: 0.85rem;
}

.geners-dropdown {
  .ui.multiple.selection.dropdown {
    display: flex;
    flex-wrap: wrap;
    height: auto !important;
    align-items: center;
  }
}

.personal-container {
  &::-webkit-scrollbar {
    width: 5px;
    height: 4px;
  }
}

.profile-title {
  font-size: 0.85rem;
  // line-height: 0.75rem;
}
.last-30-days-text-profile{
  font-size: 8px;
  text-align: end;
  color: #000;
}
.profile-table {
  border-radius: 20px;
  height: calc(100% - 10rem);
  background-color: white;
}

.forward-button {
  position: absolute;
  top:11rem;
  right: 2.5%;

  &-popup {
    border-radius: 10px !important;
  }
}

.profileCampaign-status {
  border-radius: 30px;
  font-size: 0.875rem;
  line-height: 0.875rem;
  padding: 4px 6px;
  max-width: 7.5rem;
}

.influencer-filters {
  &.ui.inline.dropdown .dropdown.icon {
    padding: 3px !important;
    margin: 0 !important;
  }
  &.ui.scrolling.dropdown .menu {
    max-height: 16rem !important;
  }
}

// @media screen and (min-width: 1919px) {
//   .profile-content {
//     &-menu {
//       padding-top: 4rem;
//     }
//   }
// }
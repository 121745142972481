.favourite-button {
  position: absolute;
  top: 15%;
  right: 4%;
}

.influencer-genres {
  padding: 0.25rem 0.5rem;
  border-radius: 20px;
  background: #EEEEEE;
  font-size: 0.75rem;
  line-height: 0.75rem;

  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
  &-hidden {
    padding: 0.5rem;
  }
}
.influencer-genres-width{
  min-width: 100px;
  max-width: 200px;
}

.discovery-geners {
  max-width: 9rem;
}

.influencer-location {
  max-width: 8rem;
}

.discovery-filters {
  // height: 11rem;

  .ui.selection.dropdown .menu>.item {
    border-top: none !important;
  }

  .ui.dropdown>.text,
  .ui.dropdown .menu>.item {
    font-size: 0.75rem !important;
    line-height: 0.75rem;
  }
}

.discovery-table {
  height: calc(100% - 13rem);
  // margin: 0.15rem 0.5rem;
}

.ui.fitted.toggle.checkbox,
.ui.toggle.checkbox input,
.ui.toggle.checkbox label:before {
  width: 2.5rem !important;
  height: 1rem !important;
  min-height: 0 !important;
}

.ui.toggle.checkbox label:after {
  width: 1rem !important;
  height: 1rem !important;
}

.ui.toggle.checkbox input:checked~label:after {
  left: 1.5rem !important;
}


.influencer-detail {
  &-img {
    width: 2.75rem;
    height: 2.75rem;
    margin-right: 0.5rem;


    img {
      border-radius: 50%;
      width: 100%;
    }
  }

  &-text1 {
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 0.875rem;

  }

  &-text2 {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 0.65rem;
  }
}
.login-password-icon {
    position: absolute;
    top: 2rem;
    right: 4%;
    z-index: 1;
}


@media screen and (min-width: 1919px) {
    .login-password-icon {
        top: 2.5rem;
    }
}
// .password-required-login{
//     position: absolute;
//     top: 35%;
//     right: 4%;
//     z-index: 1;
// }

// .password-stable-login {
//     position: absolute;
//     top: 23%;
//     right: 4%;
//     z-index: 1;
// }

@import "../../styles/core/core-variables";

.custom-input {

  input,
  textarea,
  .react-datepicker-wrapper input[type="date"] {
    border: 1px solid $grey-shade-1;
    border-radius: 10px;
    margin: 0.25rem 0 0.5rem;
    font-family: $primary-font;
    font-size: 0.75rem;
  }

  input {
    height: 2.375rem;
  }

  .pac-target-input {
    padding-left: 1rem;
  }

  input[type='checkbox'] {
    margin: 0;
  }

  textarea {
    height: 4.375rem;
    // resize: none;
  }

  input::placeholder,
  textarea::placeholder {
    color: $grey-shade-1;
    font-family: $primary-font;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.219rem;
    text-align: left;
  }

  .input-icon {
    position: absolute;
    top: 44%;
    left: 1rem;
  }

  .input-icon-stable {
    position: absolute;
    top: 32%;
    left: 1rem;
  }

  .input-search {
    position: absolute;
    top: 25%;
    left: 1rem;

    &-right {
      left: 90%;
    }
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    color: $grey-shade-1;
    font-family: $primary-font;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.219rem;
    text-align: left;

    input {
      padding: 0.5rem !important;
    }
  }

  .datepicker-input {
    cursor: pointer; 
  } 

  .react-datepicker__input-container,
  .react-datepicker__view-calendar-icon {
    z-index: 0;
  }

  .date-icon {
    color: $black-shade-2;
    background-position: right;
    cursor: pointer;
    position: absolute;
    right: 5%;
    top: 30%;
    z-index: 1;
  }

  .ui.dropdown,
  .ui.selection.dropdown {
    border: 1px solid $grey-shade-1;
    border-radius: 10px;
    margin: 0.25rem 0 0.5rem;
    font-family: $primary-font;
    height: 2.375rem;
    min-width: 5rem;
    min-height: 1rem !important;
    line-height: 0.65rem;
    font-size: 0.75rem !important;
    padding: 0.6rem !important;
    display: flex;
    align-items: center;
  }

  .ui.active.visible.selection.dropdown {
    border-bottom-right-radius: 10px !important;
    border-bottom-left-radius: 10px !important;

    .visible.menu.transition {
      border: 1px solid $grey-shade-1 !important;
      margin-top: 0.5rem !important;
      border-radius: 10px !important;
      line-height: 0.65rem;
      font-size: 0.75rem;
    }
  }

  .ui.dropdown .menu>.item {
    min-height: 1rem !important;
    line-height: 0.65rem;
    font-size: 0.75rem;
    padding: 0.6rem !important;
  }
}

.divider text,
.ui.inline.dropdown>.text {
  font-size: 0.75rem !important;
  font-weight: 400;
}

.input-label {
  font-size: 0.85rem;
  line-height: 1rem;
  font-weight: 600;
  padding-left: 0.25rem;
}

.input-asterik {
  top: -0.25rem;
  padding-left: 0.125rem;
}

.textarea-label {
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 600;
}

.otp-input {
  border: 1px solid $grey-shade-1;
  border-radius: 20px;
  margin: 4px 5px 20px;
  font-family: $primary-font;
  height: 3rem;
  text-align: center;
}

.react-datepicker__close-icon {
  height: 1rem !important;
  top: 28% !important;
  right: 0.75rem !important;
}

.uploadFile-container {
  border: 1px solid $grey-shade-1;
  border-radius: 10px;
  margin: 0.25rem 0 0.5rem;
  font-family: $primary-font;
  font-size: 0.75rem;
}

.country-dropdown {
  &.ui.dropdown {
    position: absolute;
    top: 1.35rem;
    left: 1rem;
    z-index: 1;
    flex-direction: row;
    display: flex !important;
    min-width: 1rem !important;
    height: 2rem !important;

    .dropdown.icon {
      margin: 0 !important;
    }

    &.ui.dropdown.dropdown .menu>.input {
      margin: 0.5rem !important;
      position: sticky;
    }

    &.ui.search {
      font-size: 1rem !important;
    }
  }

  &-menu {
    max-height: 150px;
    overflow: hidden;
  }
}

.edit-icon {
  top: -2px;
  right: -0.25rem;
  // background: rgb(190, 165, 156);
  border-radius: 3px;
}

@media screen and (min-width: 1919px) {
  .custom-input {

    input,
    .ui.dropdown,
    .ui.selection.dropdown {
      height: 3.125rem;
      margin: 0.25rem 0 1.25rem;
    }

    .ui.selection.dropdown {
      padding: 1rem;
    }

    .dropdown.icon {
      padding: 1rem !important;
    }
  }

  .country-dropdown {
    .dropdown.icon {
      padding: 0 !important;
    }

    &.ui.dropdown {
      top: 2rem;
    }
  }

  .input-label {
    font-size: 1rem;
    line-height: 1.25rem;
  }
}
@import "../../../styles/core/core-variables";

.campaign {
  &-card {
    border-radius: 10px;
    border: 1px solid #B3B3B3;
    background: transparent;
    width: 100%;

    &-active {
      background: white;
      border-radius: 10px 0px 0px 10px;
      border: none;
      width: 100%;
      margin-left: 2rem;
      z-index: 1;
      box-shadow: -2px 2px 4px 0px #AEAEAE40;
    }
  }

  &-name {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25rem;
    color: $blue-shade-2;
  }

  &-subName {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    color: $grey-shade-4;

  }

  &-description {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 0.875rem;
    color: $grey-shade-4;
    padding: 0.75rem 0.75rem 1rem 1rem;

  }

  &-date {
    font-size: 0.75rem;
    font-weight: 400;
    // line-height: 0.875rem;
    // text-align: left;
  }

  &-imgContainer {
    border: 1px solid $grey-shade-3;
    border-radius: 10px;
    width: 5rem;
    height: 5rem;
    img {
      object-fit: contain;
      border-radius: 10px;
      height: 100%;
      width: 100%;
    }
  }
}

.number-indicator {
  border-radius: 50%;
  background: $grey-shade-1;
  font-size: 0.625rem;
  font-weight: 600;
  line-height: 0.625rem;
  text-align: left;
  color: $black-shade-1;
  padding: 3px 5px;
}

.no-padding-card {
  padding: 0 !important;
}
@keyframes slideInFromRight {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
// .campaign-card:hover {
//   // transform: scale(1.03);
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//   // transition: transform 0.3s ease, box-shadow 0.3s ease;
//   transform: translateX(-50px); 
//   animation: slideInFromRight 1s ease forwards;
//   // animation: fadeIn 0.5s ease forwards;
// }
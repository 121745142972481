@import "../../styles/core/core-variables";

.image-name {
  width: 60px;
  height: 60px;
  border: 1px solid $grey-shade-3;
  border-radius: 10px;

  img {
    object-fit: contain;
    border-radius: inherit;
  }
}

.search-input {
  input {
    margin: 0 !important;
    height: 2.5rem !important;
  }

  .custom-svg-icon.input-search {
    top: 30% !important;
  }
}

.brandTable-height {
  height: calc(100% - 7.25rem);
}
@import "../../styles/core/core-variables";

.customButton {
  &.ui.button {
    border-radius: 0;
    min-width: 6rem;
    // min-height: 2.5rem;
    font-family: $primary-font;
    padding: 0.75rem 1.875rem;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1rem;


    &.round {
      border-radius: 20px;
    }

    &.icon-only {
      min-width: 0px;
      padding: 0.25rem;
    }

    &.no-padding {
      padding: 0 !important;
      min-width: 0;
    }

    &.no-margin {
      margin: 0;
    }

    &.disabled {
      cursor: not-allowed;
    }

    &.circular {
      border-radius: 4px;
    }

    &.text-small {
      font-size: 0.75rem;
      line-height: 1rem;
      padding: 8px 18px;
    }

    &.text-big {
      font-size: 1.375rem;
      line-height: 1.42rem;
      padding: 22px 40px;
    }

    &.primary-button {
      background-color: $primary-color;
      color: $secondary-color;
      border: 1.5px solid $primary-color;
      font-weight: 600;
     

      &.outline {
        background-color: transparent;
        color: $secondary-color;
        box-shadow: none;
      }

      &.no-outline {
        border: none;
        box-shadow: none;
      }
    }

    &.secondary-button {
      background-color: $blue-shade-2;
      color: $secondary-color;  
      font-weight: 600;   
      border: 1.5px solid $blue-shade-2;

      &.outline {
        background-color: transparent;
        color: $secondary-color;
        box-shadow: none;
      }

      &.no-outline {
        border: none;
        box-shadow: none;
      }

      &.no-button-background {
        background-color: transparent;

        &:hover {
          color: $blue-shade-2;
          text-decoration:underline;
        }
      }
    }

    &.grey-button {
      background-color: $grey-shade-1;
      color: $default-color;
      font-weight: 600;

      &.outline {
        background-color: transparent;
        border: 1px solid $grey-shade-1;
        box-shadow: none;
        border-radius: 10px;
        // padding: 0.5rem;
        margin-bottom: 0.25rem; 
        margin-left: 0.5rem;
      }

      &.no-outline {
        border: none;
        box-shadow: none;
      }

      &.notBold {
        font-weight: 400;
      }

      &.no-button-background {
        background-color: transparent;

        &:hover {
          color: $blue-shade-2;
          text-decoration:underline;
        }
      }
    }

    &.white-button {
      background-color: #FEFEFE;
      color: $blue-shade-3;
      border: 1px solid $blue-shade-3;
      border-radius: 4px;
      font-weight: 600;

      &.outline {
        border: 1px solid $orange-shade-1;
        color: $orange-shade-1;
      }
      &.no-button-background {
        background-color: transparent;

        &:hover {
          color: $blue-shade-2;
          text-decoration:underline;
        }
      }

      &.no-outline {
        border: none;
        box-shadow: none;
      }
    }

    &.danger-button {
      background-color: #FF2626;
      color: $secondary-color;
      border-radius: 4px;
      font-weight: 600;


      &.no-button-background {
        background-color: transparent;
      }
    }
    &.green-button {
      background-color: #5CBB5A;
      color: $secondary-color;
      border-radius: 4px;

      &.no-button-background {
        background-color: transparent;
      }
    }

  }
}

.ui.popup.button-popover {
  padding: $padding-1;
  // background: $black-shade-4;
  border: none;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  opacity: 0.9;
}

@media screen and (min-width: 576px) and (max-width: 1024px) {
  .customButton {
    &.ui.button {
      padding: 7px 12px;
      font-size: 0.75rem;
      line-height: 0.92rem;

      &.text-big {
        line-height: 1.35rem;
        font-size: 1rem;
        padding: 16px 32px;
      }
    }
  }
}

.ui.button {
  &.width-20p {
    min-width: 20px !important;
  }

  &.padding {
    &-1 {
      padding: $padding-1;
    }

    &-2 {
      padding: $padding-2;
    }

    &-3 {
      padding: $padding-3;
    }

    &-4 {
      padding: $padding-4;
    }

    &-l-1 {
      padding-left: $padding-1;
    }

    &-l-2 {
      padding-left: $padding-2;
    }

    &-l-3 {
      padding-left: $padding-3;
    }

    &-l-4 {
      padding-left: $padding-4;
    }

    &-r-1 {
      padding-right: $padding-1;
    }

    &-r-2 {
      padding-right: $padding-2;
    }

    &-r-3 {
      padding-right: $padding-3;
    }

    &-r-4 {
      padding-right: $padding-4;
    }

  }

  &.margin {
    &-l-1 {
      margin-left: $margin-1;
    }

    &-l-2 {
      margin-left: $margin-2;
    }

    &-l-3 {
      margin-left: $margin-3;
    }

    &-l-4 {
      margin-left: $margin-4;
    }

    &-r-1 {
      margin-right: $margin-1;
    }

    &-r-2 {
      margin-right: $margin-2;
    }

    &-r-3 {
      margin-right: $margin-3;
    }

    &-r-4 {
      margin-right: $margin-4;
    }

    &-r-5 {
      margin-right: $margin-5;
    }
  }
}
.loading-container {
    display: flex;
    align-items: center;
    transition: ease-in-out 3s;

    &.inverted {
        min-height: 300px;
        background-color: #e2e2e266;
    }
    &.global {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 2000;
        background-color: rgba(0,0,0,0.2);

    }
  

}
.ui.inline.loader {
    top:2rem ; 
}

// /* Loading.scss */
// @keyframes rotate {
//     0% {
//       transform: rotate(0deg);
//     }
//     100% {
//       transform: rotate(360deg);
//     }
//   }
  
//   .custom-loader {
//     position: relative;
//     width: 4em;
//     height: 4em;
//     border-radius: 50%;
//     border: 0.4em solid transparent;
//     border-top-color: #3498db; /* Adjust colors as needed */
//     animation: rotate 1.5s linear infinite;
//   }
  
//   .custom-loader:before {
//     content: '';
//     position: absolute;
//     top: 0.2em;
//     left: 0.2em;
//     right: 0.2em;
//     bottom: 0.2em;
//     border-radius: 50%;
//     background: linear-gradient(45deg, #3498db, #8e44ad, #3498db); /* Gradient colors */
//     animation: rotate-gradient 1.5s linear infinite;
//   }
  
//   @keyframes rotate-gradient {
//     0% {
//       transform: rotate(0deg);
//     }
//     100% {
//       transform: rotate(360deg);
//     }
//   }
  
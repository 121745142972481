.notification-title {
  font-size: 1rem;
  line-height: 1.063rem;
}

.notification-date {
  font-size: 0.625rem;
  line-height: 0.75rem;
}

.chat-records {
  padding-right: 6px;

  &::-webkit-scrollbar {
    // display: none;
    width: 4px;
  }
}
@import "../../styles/core/core-variables";

.signup-google,
.signup-facebook,
.signup-email {
  border: 1px solid $grey-shade-1;
  border-radius: 20px;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: center;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.25rem;
  cursor: pointer;
  text-align: center;

  .loading-container {
    width: 60% !important;
    .ui.inline.loader {
    top: 0 !important;
  }
}

}


.middle-text {
  position: absolute;
  top: -0.75rem;
  left: 45%;
  background: white;
  padding: 5px;
  font-size: 1rem;
  font-weight: 300;
  line-height: 0.914rem;
  text-align: center;

}

.email-input {
  &.ui.input>input {
    border-radius: 20px;
  }
}
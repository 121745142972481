@import "../../styles/core/core-variables";

.header {
  &-image {
    width: 3.75rem;
    height: 3.25rem;
    border: 1px solid $grey-shade-3;
    border-radius: 10px;
    background-color: $secondary-color;

    img {
      object-fit: contain;
    }
  }

  &-content {
    width: calc(100% - 3.75rem);
  }

  &-name {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25rem;
    color: $blue-shade-2;
  }

  &-subName {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    color: $grey-shade-4;
  }

}

.influencer-category {
  border-radius: 20px;
  border: 1px solid $border-bottom-color;
  background: $grey-shade-7 ;
  padding: 0.125rem 0.35rem;
}
.user-deleted-css{
  pointer-events: none;
  opacity: .5;
}
.influencer-detail {
  min-width: 9rem;

  &-img {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 0.5rem;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  &-profile {
    .text1 {
      font-size: 0.75rem;
      font-weight: 700;
      line-height: 0.85rem;
    }

    .text2 {
      font-size: 0.6rem;
      font-weight: 400;
    }
  }
}

.current-view {
  font-size: 0.75rem;
  font-weight: 400;

  &-active {
    border-bottom: 4px solid $primary-color;
  }
}

.ui.dropdown>.text,
.ui.dropdown .menu>.item,
.ui.active.label,
.ui.label {
  font-size: 0.75rem !important;
  line-height: 0.75rem;
}

.status-dropdown {
  &.ui.compact.selection.dropdown {
    display: flex !important;
    align-items: center;
  }

  &.ui.floating.dropdown>.menu {
    height: 7.188rem;
    max-height: 12.5rem !important;
  }
}

.table-cell {
  width: 6rem !important;
  justify-content: center;
  height: 2rem !important;

  input {
    margin: 0;
  }
}

.influencer-address {
  width: 8.5rem;
}

.influencer-campaign {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 0.75rem;
}

.fix-table-cell {
  left: 0.5rem;
  position: sticky;
  z-index: 4;
  background-color: $secondary-color !important;
  border-bottom: 1px solid $border-bottom-color;

  &-corner {
    left: -0.5rem !important;
  }

  &-start {
    left: -0.5rem !important;
  }
}

.fix-table-head {
  left: 0.5rem;
  position: sticky;
  background-color: white !important;
  z-index: 8;

  &-corner {
    left: -0.6rem !important;
  }

  &-start {
    left: -0.5rem !important;
  }
}

.user-container {
  background-color: transparent;
  overflow-x: auto;
  overflow-y: auto;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  // min-height: 20rem;
  height: calc(100% - 8rem);
  position: relative;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    border-radius: 40px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, .25);
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, .1);
    border-radius: 40px !important;

  }

  .influencer-table,
  .ui.table {
    overflow: auto;
    border-collapse: collapse;

    thead {
      position: sticky;
      top: 0;
      left: 0;
      z-index: 8;
      border-radius: 30px !important;
      border-bottom: 1px solid $border-bottom-color;
      background-color: $secondary-color !important;
    }

    thead tr {
      color: black;
      text-align: left;
      background-color: #ffffffa6;
    }

    tbody {
      height: 100%;
      z-index: 3 !important;
    }

    th {
      font-size: 0.85rem;
    }

    td {
      // padding: 10px 14px;
      font-size: 0.75rem;
    }

    tbody td {
      word-wrap: break-word;
    }
  }
}

.influencer-options {

  .ui.multiple.dropdown>.label,
  .ui.label {
    padding: .5833em .833em !important;
  }

  .divider.text {
    padding: 0.25rem !important;
  }
}

.payment-bg {
  background: #FFFCE0 !important;
}

.logistics-bg {
  background: #E0FFFB !important;
}

.content-bg {
  background: #E5FFE0 !important;
}

.insights-bg {
  background: #FAF2FF !important;
}

.chat-count {
  background-color: $danger-color;
  border-radius: 4px;
  padding: 4px;
  height: 1rem;
  font-size: 0.5rem;
  line-height: 0.5rem;
  text-align: center;
  color: $secondary-color;
  top: 0.85rem;
  left: 40%;
}

@media screen and (min-width: 1919px) {
  .user-container {
    min-height: 38rem;
  }
}
.input-label {
  font-size: 0.85rem;
  line-height: 1rem;
  font-weight: 600;
}

.input-caption {
  font-size: 0.75rem;
  line-height: 0.75rem;
  font-weight: 400;
  color: red;
}

.registration-form {
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.insta-verify-icon {
  top: 2.25rem !important;
  left: 3% !important;
}

.registration-geners {
  .ui.multiple.selection.dropdown {
    display: flex;
    flex-wrap: wrap;
    height: auto !important;
    align-items: center;
  }
}

@media screen and (min-width: 1919px) {
  .input-label {
    font-size: 1rem;
    line-height: 1.25rem;
  }

  .insta-verify-icon {
    top: 3rem !important;
  }
}
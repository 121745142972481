.password-icon {
    top: 2rem;
    right: 5%;
    z-index: 1;
    position: absolute;
}



@media screen and (min-width: 1919px) {
    .password-icon {
        top: 2.5rem;
    }
}
// .password-required-icon {
//     top: 2rem;
//     right: 5%;
//     z-index: 1;
// }

// .password-stable-icon {
//     position: absolute;
//     top: 23%;
//     right: 5%;
//     z-index: 1;
// }
@import "../../styles/core/core-variables";

.customInput {

  input,
  textarea {
    border: 1px solid $grey-shade-1;
    border-radius: 10px;
    // margin: 0.25rem 0 0.5rem;
    font-family: $primary-font;
    font-size: 0.75rem;
  }

  input {
    height: 2.375rem;
  }

  input[type='checkbox'] {
    margin: 0;
  }

  textarea {
    height: 4.375rem;
    resize: none;
  }


  input::placeholder,
  textarea::placeholder {
    color: $grey-shade-1;
    font-family: $primary-font;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.219rem;
    text-align: left;
  }

  .input-icon {
    position: absolute;
    top: 44%;
    left: 3%;
  }

  .input-icon-stable {
    position: absolute;
    top: 32%;
    left: 3%;
  }

  .input-search {
    position: absolute;
    top: 25%;
    left: 1rem;

    &-right {
      left: 90%;
    }
  }
}

.input-label {
  font-size: 0.85rem;
  line-height: 1rem;
  font-weight: 600;
  padding-left: 0.25rem;
}

.otp-input {
  border: 1px solid $grey-shade-1;
  border-radius: 20px;
  margin: 4px 5px 20px;
  font-family: $primary-font;
  height: 3rem;
  text-align: center;
}

.placeholder-text {
  color: rgba(191, 191, 191, .87);
}

.custom-dropdown {
  position: relative;
  min-width: 14em;
  min-height: 2.71428571em;
  z-index: 10;
  &-icon {
    cursor: pointer;
    position: absolute;
    // width: auto;
    // height: auto;
    line-height: 0.25rem !important;
    top: .78571429em;
    right: 1em;
    z-index: 10;
    margin: -.78571429em;
    padding: .91666667em;
    opacity: .8;
    transition: opacity .1s ease;
    font-size: 0.25rem;
  }
}

.dropdown-toggle {
  position: relative;
  min-width: 14em;
  min-height: 2.71428571em;
  z-index: 10;
  padding: .78571429em 2.1em .78571429em 1em;
  cursor: pointer;
  background-color: #fff;
  box-shadow: none;
  border: 1px solid rgba(34, 36, 38, .15);
  border-radius: .28571429rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  word-wrap: break-word;
  white-space: normal;
  outline: 0;
  z-index: 10;
  font-size: 0.75rem;
  line-height: 0.75rem;
  transition: box-shadow .1s ease, width .1s ease, -webkit-box-shadow .1s ease;
}

.dropdown-toggle:hover {
  border-color: rgba(34, 36, 38, .35);
}

.dropdown-options {
  position: absolute;
  top: 98%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #96c8da;
  border-top: none;
  z-index: 10;
  max-height: 16.02857143rem;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }

}

.dropdown-options-open {
  box-shadow: 0 2px 3px 0 rgba(34, 36, 38, .15);
  border-radius: 0 0 .28571429rem .28571429rem;
}

.dropdown-toggle-open {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom: none;
  border-color: #96c8da;
  box-shadow: 0 2px 3px 0 rgba(34, 36, 38, .15);
}

.option {
  cursor: pointer;
  padding: .78571429rem 1.14285714rem;
  transition: background-color 0.2s ease;
  font-size: 0.75rem !important;
  line-height: 0.75rem;
}

.option:hover {
  background-color: #f0f0f0;
}


.range-inputs {
  height: 2.75rem !important;

  input {
    margin-bottom: 0.25rem !important;
  }
}

@media screen and (min-width: 1919px) {
  .dropdown-options {
    top: 99%;
  }

  .input-label {
    font-size: 1rem;
    line-height: 1.25rem;
  }
}
@import "./core/index";

html,
body {
  margin: 0;
  height: 100%;
  width: 100%;
  font-size: 14px;
  font-family: $primary-font;
  color: #000000;

  @media only screen and (min-width: 1920px) {
    font-size: 16px;
  }
}

#root {
  height: 100%;
  width: 100%;
  position: relative;
}

body {
  margin: 0;
  background-color: #FFFFFF;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #2aa70c;
  border-radius: 10px;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #929e8f;
  background-color: #929e8f;
}
@import "../../../styles/core/core-variables";

.profile-modal {
  &.ui.modal>.content {
    // padding: 0;
    background: $grey-shade-5;
    min-height: 300px;
    display: flex !important;
  }

  &-toggle-icon {
    position: absolute;
    right: 0.25rem;
    top: 0.5rem;
  }
}

.content-view {
  max-height: 30rem;
  // height: 50rem;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.social-media-view {
  border-radius: 20px;
  height: calc(100% - 10rem);
  // max-height: 30rem;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

}
.middle-text {
    position: absolute;
    top: -0.75rem;
    left: 45%;
    background: white;
    padding: 5px;
    font-size: 0.75rem;
    font-weight: 300;
    line-height: 0.914rem;
    text-align: center;
}

.invite-back {
    position: absolute;
    top: 4%;
    right: 1%;
}
@import "../../styles/core/core-variables";

.profile-genres {
  padding: 0.25rem 0.5rem;
  border-radius: 20px;
  background: $grey-shade-7 ;
  font-size: 0.6rem; 
  line-height: 0.75rem;
  &-hidden {
    padding: 0.5rem;
  }
}

.profileHeader-container {
  position: relative;
  width: 94%;
  height: 10rem;
  border: 1px solid $grey-shade-1;
  border-radius: 20px;
  padding: 0.5rem;
  margin-left: 1rem;

  // &:hover {
  //    transform: scale(1.03);
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  // transition: transform 0.3s ease, box-shadow 0.3s ease;
  // }

  &-left {
    margin-left: 6rem;
  }

  &-img {
    width: 6.5rem;
    left: -3%;
    top: 1.5rem;

    img {
      border-radius: 20px;
    }
  }

  &-text {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5rem;
  }
}

.profileHeaderKeys {
  font-size: 0.75rem;
  font-weight: 300;
  line-height: 0.914rem;
  text-align: center;
}

.profileHeaderValues {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.524rem;
  text-align: center;
}


@media screen and (min-width: 1919px) {
  .profileHeader-container {
    &-left {
      margin-left: 6rem;
    }

    &-img {
      width: 7.75rem;
      top: 9%;
    }
  }
}
.tooltip-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.tooltip-text-normal {
    white-space: normal;
}
.tooltip-popup {
    &.ui.popup {
        font-size: 0.75rem;
    }
    .content {
        background-color: #ffffff;
    }
    &.ui.top.popup {
        margin: 0 !important;
    }
}